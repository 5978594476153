"use client";

import { SignUpButton } from "@clerk/nextjs";
import { Box, Button, Center, Flex, Group, Paper, rem, Stack, Text, Title } from "@mantine/core";
import Image from "next/image";

import IconRacket from "@/components/IconRacket/IconRacket";

import classes from "./HomePublicPage.module.css";
import SignUpSignIn from "./SignUpSignIn";
import Testimonials from "./Testimonials";

export default function HomePublicPage() {
  return (
    <Box className={classes.container}>
      <Title c="white" fw={500} className={classes.tagline}>
        We enable connections on and off the court
      </Title>

      <Flex justify="center" align="center" direction="row" wrap="wrap" gap="20" mt="40">
        <Paper className={classes.card} radius="md" withBorder>
          <Text size="xl" fw={500} className={classes.cardTitle}>
            Play Tennis
          </Text>

          <Stack>
            <div className={classes.wrapper}>
              <IconRacket style={{ width: rem(42), height: rem(42), flexShrink: 0 }} />
              <Text>Centralize all your tennis communications</Text>
            </div>
            <div className={classes.wrapper}>
              <IconRacket style={{ width: rem(42), height: rem(42), flexShrink: 0 }} />
              <Text>Stay connected with your tennis partners and make new friends</Text>
            </div>
            <div className={classes.wrapper}>
              <IconRacket style={{ width: rem(42), height: rem(42), flexShrink: 0 }} />
              <Text>Unlimited group chats</Text>
            </div>
          </Stack>

          <Stack style={{ textAlign: "center", marginTop: 20, alignItems: "center" }}>
            <Text fw={600}>Here's an example of our chat</Text>

            <Image
              src="/images/chat-example.png"
              alt="Chat Example"
              width={0}
              height={0}
              sizes="1"
              style={{ width: "100%", height: "auto", borderRadius: 10 }}
            />
          </Stack>
        </Paper>

        <Paper className={classes.card} radius="md" withBorder>
          <Text size="xl" fw={500} className={classes.cardTitle}>
            Get-Togethers
          </Text>

          <Stack>
            <div className={classes.wrapper}>
              <IconRacket style={{ width: rem(42), height: rem(42), flexShrink: 0 }} />
              <Text>Off-court get-togethers with friends</Text>
            </div>
            <div className={classes.wrapper}>
              <IconRacket style={{ width: rem(42), height: rem(42), flexShrink: 0 }} />
              <Text>Connect with people who share common interests</Text>
            </div>
            <div className={classes.wrapper}>
              <IconRacket style={{ width: rem(42), height: rem(42), flexShrink: 0 }} />
              <Text>Exciting social gatherings and new experiences</Text>
            </div>
          </Stack>

          <div style={{ textAlign: "center", marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
            <Text fw={600} style={{ marginBottom: 20 }}>
              Here are examples of events near you
            </Text>

            <Stack style={{ textAlign: "left", padding: 25, border: "1px solid gray" }}>
              <Group>
                <div style={{ flexGrow: 1 }}>
                  <Text fw={700}>Talk tennis strategy</Text>
                  <Text>Silver moon bakery</Text>
                  <Text>Friday, Sept 1 at 9am</Text>
                </div>

                <SignUpButton mode="modal">
                  <Button>RSVP</Button>
                </SignUpButton>
              </Group>
              <Group>
                <div style={{ flexGrow: 1 }}>
                  <Text fw={700}>Watch US Open</Text>
                  <Text>Cafe du Soleil</Text>
                  <Text>Sunday Sept 14 at 11am</Text>
                </div>

                <SignUpButton mode="modal">
                  <Button>RSVP</Button>
                </SignUpButton>
              </Group>
            </Stack>
          </div>
        </Paper>
      </Flex>

      <Box className={classes.testimonials} mt="lg">
        <Testimonials />
      </Box>

      <Center mt="xl">
        <SignUpSignIn />
      </Center>
    </Box>
  );
}

"use client";

import { Anchor, Group, type MantineStyleProp, ThemeIcon, Tooltip, useComputedColorScheme } from "@mantine/core";
import { IconCalendarPlus, type IconProps } from "@tabler/icons-react";
import { atcb_action } from "add-to-calendar-button";
import { useRef } from "react";

import type { EventSchema } from "@/cms/schema";
import {
  formatCmsDate,
  formatEventDate,
  formatEventFullDates,
  formatEventLocation,
  parseCmsDate,
  parseCmsDateTimezone,
  sanityPortableTextToPlain,
} from "@/utils/helpers";

interface EventDateProps {
  event: EventSchema;
  iconProps?: IconProps;
  iconContainerStyle?: MantineStyleProp;
  dateFormat: "formatEventDate" | "formatEventFullDates";
}

export function EventDate({ event, iconProps, iconContainerStyle, dateFormat }: EventDateProps) {
  const colorScheme = useComputedColorScheme();
  const ref = useRef<HTMLDivElement>(null);

  // Some calendars need an end date to correctly add the event
  const formatDefaultEndDate = (format: string) => {
    if (!event.startDate) return;
    const startDate = parseCmsDate(event.startDate);
    const endDate = startDate.add(1, "hour");
    return formatCmsDate(endDate, format);
  };

  const addToCalendar = async () => {
    if (!event.startDate) return;

    await atcb_action(
      {
        name: event.name,
        description: sanityPortableTextToPlain(event.longDescription || event.description),
        startDate: formatCmsDate(event.startDate, "YYYY-MM-DD"),
        startTime: formatCmsDate(event.startDate, "HH:mm"),
        endDate: formatCmsDate(event.endDate, "YYYY-MM-DD") || formatDefaultEndDate("YYYY-MM-DD"),
        endTime: formatCmsDate(event.endDate, "HH:mm") || formatDefaultEndDate("HH:mm"),
        timeZone: parseCmsDateTimezone(event.startDate),
        location: formatEventLocation(event, "\n"),
        options: ["Apple", "Google", "iCal", "Outlook.com", "Yahoo", "Microsoft365", "MicrosoftTeams"],
        hideBranding: true,
        listStyle: "overlay",
        buttonStyle: "text",
        size: "4",
        lightMode: colorScheme,
        styleLight: "--overlay-cursor: initial",
        styleDark: "--overlay-cursor: initial",
      },
      ref.current ?? undefined,
    );
  };

  if (!event.startDate) return null;

  return (
    <Tooltip label="Save to my calendar" withArrow>
      <Group ref={ref} wrap="nowrap" gap={0}>
        <ThemeIcon
          variant="subtle"
          onClick={() => addToCalendar()}
          bd={0}
          style={[{ cursor: "pointer", marginLeft: -6, marginRight: 0 }, iconContainerStyle]}
        >
          <IconCalendarPlus {...iconProps} />
        </ThemeIcon>

        <Anchor size="sm" onClick={() => addToCalendar()}>
          {dateFormat === "formatEventFullDates" ? formatEventFullDates(event) : formatEventDate(event.startDate)}
        </Anchor>
      </Group>
    </Tooltip>
  );
}

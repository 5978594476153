"use client";

import { useUser } from "@clerk/nextjs";
import { Session } from "@talkjs/react";
import { useCallback } from "react";
import Talk from "talkjs";

import { env } from "@/env-client";
import { getChatToken } from "@/serverActions/chat";
import { formatUserFullName } from "@/utils/helpers";

interface ChatContextClientProps {
  token: string;
  children?: React.ReactNode;
}

export function ChatContextClient(props: ChatContextClientProps) {
  const { token, children } = props;

  const { user } = useUser();

  const syncUser = useCallback(() => {
    if (!user) {
      return null as unknown as Talk.User;
    }

    return new Talk.User({
      id: user.id,
      name: formatUserFullName(user),
      email: user.primaryEmailAddress?.emailAddress,
      photoUrl: user.imageUrl,
    });
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <Session appId={env.NEXT_PUBLIC_TALKJS_APP_ID} token={token} tokenFetcher={getChatToken} syncUser={syncUser}>
      {children}
    </Session>
  );
}

"use client";

import { ActionIcon, type ActionIconProps } from "@mantine/core";
import { IconShare3 } from "@tabler/icons-react";

import { env } from "@/env-client";
import { showErrorNotification, showSuccessNotification } from "@/utils/clientHelpers";

interface InviteShareProps extends ActionIconProps {
  url?: string;
  title?: string;
  text?: string;
  children?: React.ReactNode;
}

export function InviteShare(props: InviteShareProps) {
  const { url, title, text, children, ...actionIconProps } = props;

  const fullUrl = url
    ? `${env.NEXT_PUBLIC_MAIN_SITE_BASE_URL}${!url.startsWith("/") ? "/" : ""}${url}`
    : env.NEXT_PUBLIC_MAIN_SITE_BASE_URL;

  return (
    <ActionIcon
      {...actionIconProps}
      onClick={async () => {
        let result = false;

        if (("share" as string) in navigator) {
          try {
            await navigator.share({
              url: fullUrl,
              title,
              text,
            });
            result = true;
          } catch (error) {
            console.error(error);

            // Do not show an alert if the user cancels the share operation
            if (error instanceof Error && error.name === "AbortError") {
              result = true;
            }
          }
        } else if (("clipboard" as string) in navigator) {
          try {
            await navigator.clipboard.writeText(fullUrl);
            result = true;

            showSuccessNotification({
              message: "Invite link copied to clipboard.",
            });
          } catch (error) {
            console.error(error);
          }
        }

        if (!result) {
          showErrorNotification({
            message: "Something went wrong, please try again.",
          });
        }
      }}
    >
      {children || <IconShare3 size={40} stroke={1} />}
    </ActionIcon>
  );
}

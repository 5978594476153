"use client";

import { Anchor, Group, type MantineStyleProp, ThemeIcon, Tooltip, useComputedColorScheme } from "@mantine/core";
import { IconCalendarPlus, type IconProps } from "@tabler/icons-react";
import { atcb_action } from "add-to-calendar-button";
import { useRef } from "react";

import type { Match } from "@/db/schema";
import {
  formatMatchDateAndLength,
  formatMatchEndDate,
  formatMatchLocation,
  formatMatchStartDate,
} from "@/utils/helpers";

interface MatchDateProps {
  match: Pick<Match, "startDate" | "startTime" | "length" | "name" | "information" | "location">;
  iconProps?: IconProps;
  iconContainerStyle?: MantineStyleProp;
}

export function MatchDate({ match, iconProps, iconContainerStyle }: MatchDateProps) {
  const colorScheme = useComputedColorScheme();
  const ref = useRef<HTMLDivElement>(null);

  const addToCalendar = async () => {
    await atcb_action(
      {
        name: match.name,
        description: match.information || undefined,
        startDate: formatMatchStartDate(match, "YYYY-MM-DD"),
        startTime: formatMatchStartDate(match, "HH:mm"),
        endDate: formatMatchEndDate(match, "YYYY-MM-DD"),
        endTime: formatMatchEndDate(match, "HH:mm", 1), // Some calendars need an end date to correctly add the event
        timeZone: "currentBrowser", // ToDo: infer the timezone from the location and save it in the database
        location: formatMatchLocation(match, "\n"),
        options: ["Apple", "Google", "iCal", "Outlook.com", "Yahoo", "Microsoft365", "MicrosoftTeams"],
        hideBranding: true,
        listStyle: "overlay",
        buttonStyle: "text",
        size: "4",
        lightMode: colorScheme,
        styleLight: "--overlay-cursor: initial",
        styleDark: "--overlay-cursor: initial",
      },
      ref.current ?? undefined,
    );
  };

  return (
    <Tooltip label="Save to my calendar" withArrow>
      <Group ref={ref} wrap="nowrap" gap={0}>
        <ThemeIcon
          variant="subtle"
          onClick={() => addToCalendar()}
          bd={0}
          style={[{ cursor: "pointer", marginLeft: -6, marginRight: 0 }, iconContainerStyle]}
        >
          <IconCalendarPlus {...iconProps} />
        </ThemeIcon>

        <Anchor size="sm" onClick={() => addToCalendar()}>
          {formatMatchDateAndLength(match)}
        </Anchor>
      </Group>
    </Tooltip>
  );
}

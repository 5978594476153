import classes from "./IconRacket.module.css";

export interface IconRacketProps extends React.ComponentPropsWithoutRef<"svg"> {}

const IconRacket = ({ ...others }: IconRacketProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34 76"
    role="img"
    aria-label="Tennis Racket"
    className={classes.icon}
    {...others}
  >
    <title>Tennis Racket</title>

    <path d="M16.906 37.625c-7.87 0-14.255-7.807-14.255-17.432 0-9.625 6.385-17.433 14.255-17.433 7.875 0 14.26 7.808 14.26 17.433s-6.385 17.432-14.26 17.432Zm4.584 2.766-.016.026c-.01.02-.026.036-.042.057-2.078 2.953-3.213 8.646-3.375 9.484 0 .026-.005.047-.01.073l-.01.037a1.172 1.172 0 0 1-2.256 0l-.01-.047-.01-.037c-.756-3.557-3.422-9.593-3.422-9.593v-.006a.65.65 0 0 1-.089-.333.71.71 0 0 1 .714-.703c.02 0 .036.005.057.01v-.005h7.781v.005a.276.276 0 0 1 .063-.01.71.71 0 0 1 .713.703.65.65 0 0 1-.088.333v.006Zm11.906-20.198C33.396 9.057 26.016.03 16.906.03 7.802.031.416 9.057.416 20.193c0 7.724 3.558 14.437 8.777 17.823l-.005-.006s4.541 3.198 5.208 13.652v23.37h5.026V51.24c.036-1.672.531-8.105 5.854-13.672l-.005.005c4.864-3.51 8.125-9.98 8.125-17.38" />
  </svg>
);

export default IconRacket;

"use client";

import { SignIn, SignUp } from "@clerk/nextjs";
import { usePathname } from "next/navigation";
import { useCallback, useState } from "react";

import { addClerkTermsDisclaimer } from "@/utils/clientHelpers";

export default function SignUpSignIn() {
  const pathname = usePathname();
  const [showSignUp, setShowSignUp] = useState(true);

  const signUpRef = useCallback((signUpNode: HTMLElement | null) => {
    if (signUpNode) {
      addClerkTermsDisclaimer(signUpNode);
    }
  }, []);

  return (
    <div
      onClickCapture={(event) => {
        const node = event.target as HTMLElement;
        if (
          !node.classList?.contains("cl-footerActionLink") ||
          node.parentElement?.classList?.contains("ad-in-terms")
        ) {
          return;
        }

        event.stopPropagation();
        event.preventDefault();

        setShowSignUp(!showSignUp);
      }}
    >
      {showSignUp ? (
        <div ref={signUpRef}>
          <SignUp routing="virtual" redirectUrl={pathname} />
        </div>
      ) : (
        <SignIn routing="virtual" redirectUrl={pathname} />
      )}
    </div>
  );
}

import NextLink, { type LinkProps } from "next/link";
import { forwardRef } from "react";

import { onStart } from "../events";

import { shouldTriggerStartEvent } from "./should-trigger-start-event";

export const Link = forwardRef<HTMLAnchorElement, React.ComponentProps<"a"> & LinkProps>(
  ({ href, onClick, scroll, ...rest }, ref) => {
    const hrefAsserted = href ?? "";
    const useLink = hrefAsserted.startsWith("/");
    if (!useLink) {
      return <a href={hrefAsserted} onClick={onClick} {...rest} />;
    }

    return (
      <NextLink
        href={hrefAsserted}
        onClick={(event) => {
          if (shouldTriggerStartEvent(hrefAsserted, event)) {
            onStart();
          }
          if (onClick) {
            onClick(event);
          }
        }}
        scroll={scroll}
        {...rest}
        ref={ref}
      />
    );
  },
);

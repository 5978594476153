"use client";

import { useAuth } from "@clerk/nextjs";
import { Box, useComputedColorScheme } from "@mantine/core";
import { Inbox, Popup } from "@talkjs/react";
import { useCallback, useRef } from "react";
import Talk from "talkjs";

export interface ChatDisplayClientProps {
  mode: "inbox" | "chatbox" | "popup";
  oneOnOne?: {
    otherUser: {
      id: string;
      name: string;
      email?: string | null;
      photoUrl?: string | null;
    };
  };
}

export function ChatDisplayClient(props: ChatDisplayClientProps) {
  const { mode, oneOnOne } = props;

  const { userId } = useAuth();
  const popupRef = useRef<Talk.Popup>();
  const colorScheme = useComputedColorScheme();

  const theme = colorScheme === "dark" ? "default_dark" : "default";

  const syncConversation = useCallback(
    (session: Talk.Session) => {
      if (!userId || !oneOnOne?.otherUser) {
        return null as unknown as Talk.ConversationBuilder;
      }

      const conversationId = Talk.oneOnOneId(userId, oneOnOne.otherUser.id || "");
      const conversation = session.getOrCreateConversation(conversationId);
      conversation.setParticipant(session.me);
      conversation.setParticipant(
        new Talk.User({
          id: oneOnOne.otherUser.id,
          name: oneOnOne.otherUser.name,
          email: oneOnOne.otherUser.email,
          photoUrl: oneOnOne.otherUser.photoUrl,
        }),
      );

      setTimeout(() => {
        if (popupRef.current?.isAlive) {
          popupRef.current?.show();
        }
      }, 500);

      return conversation;
    },
    [userId, oneOnOne?.otherUser],
  );

  if (oneOnOne && (!userId || !oneOnOne.otherUser)) {
    return null;
  }

  if (mode === "inbox") {
    return (
      <Box
        data-theme-change-needs-page-reload
        style={{ colorScheme: "white", minHeight: "400px" }}
        w={{ base: "100%", sm: "680px" }}
      >
        {/*
          Hide Test Mode banner by adding `initial;} .NoticeBar { display: none; }` to
          layout > Chat panel > boxShadow in https://talkjs.com/dashboard/themes/default and
          https://talkjs.com/dashboard/themes/default_dark
        */}
        {/*
          Hide the group members in the header by adding: `display: none;` to .subtitle in
          ChatHeader for both default and default_dark themes.
        */}
        <Inbox theme={theme} style={{ height: "100%" }} />
      </Box>
    );
  }

  if (mode === "popup") {
    return (
      <Popup syncConversation={syncConversation} theme={theme} popupRef={popupRef} launcher="never" showCloseInHeader />
    );
  }
}

"use client";

import { useSessionStorage } from "@mantine/hooks";
import { type NotificationData, notifications } from "@mantine/notifications";
import { type Dispatch, type SetStateAction, useState } from "react";
import superjson from "superjson";
import useSWR, { type KeyedMutator } from "swr";

import classes from "./notifications.module.css";

export function useSessionStorageOnce<T>(
  key: string,
  defaultValue: T,
  formInitialize?: (data: NonNullable<T>) => void,
): readonly [T, (value: T) => void] {
  const [value, setValue] = useState<T>(defaultValue);

  const [storageValue, setStorageValue, removeValue] = useSessionStorage({
    key,
    defaultValue,
    serialize: superjson.stringify,
    deserialize: (value) => {
      if (value === undefined) return defaultValue;

      const parsedValue = superjson.parse(value) as T;
      setValue(parsedValue);

      setTimeout(() => {
        removeValue();
      });

      if (parsedValue !== undefined && parsedValue !== null) {
        formInitialize?.(parsedValue);
      }

      return parsedValue;
    },
  });

  return [
    value ?? storageValue,
    (value: T) => {
      setValue(value);
      setStorageValue(value);
    },
  ];
}

export function useFetchServerAction<TAction extends () => Promise<Awaited<ReturnType<TAction>>>>(
  key: string,
  serverAction: TAction,
  options?: {
    formInitialize?: (data: NonNullable<Awaited<ReturnType<TAction>>>) => void;
    errorNotification?: NotificationData | string;
  },
) {
  const { data, error, isLoading, mutate } = useSWR(key, serverAction, {
    onSuccess: (data) => {
      if (options?.formInitialize && data) {
        options.formInitialize(data);
      }
    },
    onError: (serverError) => {
      console.error(serverError);
      if (options?.errorNotification) {
        showErrorNotification(options.errorNotification);
      }
    },
  });

  return { data, error, isLoading, mutate };
}

export async function postServerAction<
  TAction extends (data: Parameters<TAction>[0]) => Promise<Awaited<ReturnType<TAction>>>,
  TData extends Parameters<TAction>[0],
>(
  serverAction: TAction,
  data: TData,
  options: {
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    successNotification: NotificationData | string;
    errorNotification: NotificationData | string;
    mutate?: KeyedMutator<TData | undefined>;
  },
): Promise<{ success: boolean; response?: Awaited<ReturnType<TAction>>; error?: unknown }> {
  options.setIsLoading(true);

  try {
    const response = await serverAction(data);

    if (options.mutate) {
      await options.mutate(data);
    }

    options.setIsLoading(false);
    showSuccessNotification(options.successNotification);

    return { success: true, response };
  } catch (error) {
    console.error(error);

    options.setIsLoading(false);
    showErrorNotification(options.errorNotification);

    return { success: false, error };
  }
}

export function showSuccessNotification(notification: NotificationData | string) {
  notifications.show(
    typeof notification === "string"
      ? {
          classNames: classes,
          title: "Success",
          message: notification,
        }
      : {
          classNames: classes,
          title: "Success",
          ...notification,
        },
  );
}

export function showErrorNotification(notification: NotificationData | string) {
  notifications.show(
    typeof notification === "string"
      ? {
          classNames: classes,
          title: "Error",
          color: "red",
          message: notification,
        }
      : {
          classNames: classes,
          title: "Error",
          color: "red",
          ...notification,
        },
  );
}

export function addClerkTermsDisclaimer(signUpNode: HTMLElement) {
  if (signUpNode.querySelector(".ad-in-terms")) return;

  const footerNode = signUpNode.querySelector<HTMLElement>(".cl-footer");
  if (!footerNode) {
    setTimeout(() => addClerkTermsDisclaimer(signUpNode), 100);
    return;
  }

  const footerActionTextNode = signUpNode.querySelector<HTMLElement>(".cl-footerActionText");
  const footerActionLinkNode = signUpNode.querySelector<HTMLElement>(".cl-footerActionLink");
  const currentTermsNode = signUpNode.querySelector<HTMLElement>(".cl-footerPagesLink__terms");

  const newTermsTextNode = document.createElement("span");
  newTermsTextNode.textContent = "By creating an account I confirm that I am at least 18 years old and I agree to the ";
  if (footerActionTextNode) {
    newTermsTextNode.className = footerActionTextNode.className;
  }

  const newTermsActionNode = document.createElement("a");
  newTermsActionNode.text = "Terms of Service";
  newTermsActionNode.href = "/terms-of-service";
  newTermsActionNode.target = "_blank";
  if (footerActionLinkNode) {
    newTermsActionNode.className = footerActionLinkNode.className;
  }

  const newTermsNode = document.createElement("div");
  newTermsNode.className = "ad-in-terms";
  newTermsNode.appendChild(newTermsTextNode);
  newTermsNode.appendChild(newTermsActionNode);
  newTermsNode.appendChild(document.createTextNode("."));

  footerNode.style.flexWrap = "wrap";
  footerNode.appendChild(newTermsNode);

  if (currentTermsNode) {
    currentTermsNode.style.display = "none";
  }
}

"use client";

import { Button, Group, Modal, Text } from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";

import { TransitionLink } from "@/components/TransitionLink/TransitionLink";

export function WelcomeModal() {
  const [welcomeModalShown, setWelcomeModalShown] = useLocalStorage({
    key: "welcomeModalShown",
    defaultValue: false,
  });

  return (
    <>
      {!welcomeModalShown && (
        <div
          style={{
            zIndex: 999,
          }}
        >
          <Fireworks autorun={{ speed: 0.4 }} />
        </div>
      )}

      <Modal
        opened={!welcomeModalShown}
        onClose={() => setWelcomeModalShown(true)}
        title={
          <Text size="lg" fw={700}>
            Welcome to AD-IN 🎾
          </Text>
        }
        closeOnClickOutside
        closeOnEscape
        centered
        size="lg"
        padding="lg"
      >
        <Text>To fully experience AD-IN, we encourage you to:</Text>
        <ul>
          <li>Complete your tennis profile</li>
          <li>Introduce yourself in the General chat</li>
          <li>Check-out Get-Togethers and join events, after which you will be added to the events chats as well</li>
        </ul>

        <Group justify="center" mt="xl">
          <Button component={TransitionLink} href="/my-tennis-profile" onClick={() => setWelcomeModalShown(true)}>
            Let's complete my tennis profile!
          </Button>
        </Group>
      </Modal>
    </>
  );
}
